.phone-input {
  border-radius: 16px !important;
  font-weight: 600;
  border: 1px solid #f1f1f1 !important;
}

.phone-input:focus {
  border: 1px solid #197d8f !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.phone-input::placeholder {
  color: #bbbbbb !important;
}

.helper_phone_text {
  color: #bbbbbb;
  font-size: 12px;
  margin-top: 5px;
}

.fee_warning {
  color: #979797;
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
}
